import React from "react";

import Layout from "../layout";
import SEO from "../components/seo";

import { Container, Row, Col } from "reactstrap";
import Heading from "../components/atoms/Heading/Heading";
import { useTerms } from "../hooks/strapi";

const TermsAndConditions = () => {
    const terms = useTerms();

    return (
        <Layout>
            <SEO title="Regulamin" />
            <Container className="mb-5 pb-5">
                <Row className="mb-5 pb-5">
                    <Col lg={{ size: 10, offset: 1 }}>
                        <Heading className="mt-5">{terms.name}</Heading>
                        <div
                            dangerouslySetInnerHTML={{
                                __html: terms.description,
                            }}
                        ></div>
                    </Col>
                </Row>
            </Container>
        </Layout>
    );
};

export default TermsAndConditions;
